import Gallery from './_gallery-tabs-gallery';
export default class GalleryJournal {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.prevEl = this.root.querySelector('[data-js-journal-prev]');
        this.nextEl = this.root.querySelector('[data-js-journal-next]');
        this.galleryEl = this.root.querySelector('[data-js-journal-gallery]');

        this.tabsData = JSON.parse(this.root.getAttribute('data-js-journal'));

        this.activeIndex = 0;
        this.galleryInstance = null;

        return true;
    }

    init() {
        this.setNav();
    }

    setNav() {
        this.onPrev = this.onPrevClick.bind(this);
        this.prevEl.addEventListener('click', this.onPrev);

        this.onNext = this.onNextClick.bind(this);
        this.nextEl.addEventListener('click', this.onNext);

        this.setNavTexts();
    }

    async onPrevClick() {
        this.activeIndex -= 1;
        if (this.activeIndex < 0) {
            this.activeIndex = 0;
            return;
        }

        this.setNavTexts();
        this.loadGallery();
    }

    async onNextClick() {
        this.activeIndex += 1;
        if (this.activeIndex > this.tabsData.length - 1) {
            this.activeIndex = this.tabsData.length - 1;
            return;
        }

        this.setNavTexts();
        this.loadGallery();
    }

    setNavTexts() {
        let prevIndex = this.activeIndex - 1;
        let nextIndex = this.activeIndex + 1;

        if (prevIndex < 0) prevIndex = 0;
        if (nextIndex > this.tabsData.length - 1)
            nextIndex = this.tabsData.length - 1;

        if (this.prevEl) {
            const prevTextEl = this.prevEl.querySelector(
                '.gallery-tabs-journal__navText'
            );
            const prevText = this.tabsData[prevIndex]['period'];

            if (prevIndex === this.activeIndex) {
                this.prevEl.classList.add('-hide');
            } else {
                this.prevEl.classList.remove('-hide');
                prevTextEl.innerHTML = prevText;
            }
        }

        if (this.nextEl) {
            const nextTextEl = this.nextEl.querySelector(
                '.gallery-tabs-journal__navText'
            );
            const nextText = this.tabsData[nextIndex]['period'];

            if (nextIndex === this.activeIndex) {
                this.nextEl.classList.add('-hide');
            } else {
                this.nextEl.classList.remove('-hide');
                nextTextEl.innerHTML = nextText;
            }
        }
    }

    async loadGallery() {
        const formData = new FormData();
        if (this.tabsData)
            formData.append(
                'gallery',
                this.tabsData[this.activeIndex]['gallery']
            );

        const request = await fetch(`${home_url}/wp-json/journal/v1/get`, {
            method: 'POST',
            body: formData,
        });

        const res = await request.json();
        const { html } = res;

        this.galleryEl.innerHTML = html;
        this.setGallery();
    }

    setGallery() {
        if (!this.galleryEl) return;

        const galleryInnerEl =
            this.galleryEl.querySelector('[data-js-gallery]');
        this.galleryInstance = new Gallery(galleryInnerEl);
    }
}
