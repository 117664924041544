import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import Popup from './popup/_popup';
export default class Gallery {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.wrapperEl = this.root.querySelector('[data-js-gallery-wrapper]');
        this.moreEl = this.root.querySelector('[data-js-gallery-more]');
        this.sliderEl = this.root.querySelector('[data-js-gallery-slider]');

        this.settings = JSON.parse(this.root.getAttribute('data-settings'));
        this.galleryData = JSON.parse(
            this.root.getAttribute('data-js-gallery')
        );
        this.currentPage = 1;
        this.maxPages = parseInt(this.settings.max_pages);

        this.html = this.wrapperEl.innerHTML;
        this.galleryInstance = null;

        return true;
    }

    init() {
        this.setMoreButton();
        this.setSlider();
        this.setLightbox();
    }

    setMoreButton() {
        if (!this.moreEl) return;

        this.onMore = this.onMoreButton.bind(this);
        this.moreEl.addEventListener('click', this.onMore);
    }

    setSlider() {
        if (!this.sliderEl) return;

        this.slider = new Swiper(this.sliderEl, {
            modules: [Navigation],
            slidesPerView: 1,
            modules: [Navigation],
            slidesPerView: 1,
            loop: true,
            navigation: {
                prevEl: '.js-slider-prev',
                nextEl: '.js-slider-next',
            },
        });
    }

    async onMoreButton() {
        this.currentPage += 1;

        await this.loadGallery();

        if (this.currentPage >= this.maxPages) {
            this.moreEl.classList.add('d-none');
        }
    }

    async loadGallery() {
        const formData = new FormData();
        if (this.galleryData) formData.append('gallery', this.galleryData);
        if (this.currentPage) formData.append('page', this.currentPage);

        const request = await fetch(`${home_url}/wp-json/gallery/v1/get`, {
            method: 'POST',
            body: formData,
        });

        const res = await request.json();
        const { html } = res;

        this.html = this.html + html;
        this.wrapperEl.innerHTML = this.html;

        this.setLightbox();
    }

    setLightbox() {
        this.removeListeners();

        this.galleriesEl = this.root.querySelectorAll(
            '.gallery-tabs-gallery__item:not(.-slider)'
        );
        this.galleriesSliderEl = this.root.querySelectorAll(
            '.gallery-tabs-gallery__item.-slider'
        );

        this.onGalleryEl = this.onGalleryElClick.bind(this);
        this.galleriesEl.forEach(item => {
            item.addEventListener('click', this.onGalleryEl);
        });

        this.onGallerySliderEl = this.onGallerySliderElClick.bind(this);
        this.galleriesSliderEl.forEach(item => {
            item.addEventListener('click', this.onGallerySliderEl);
        });
    }

    removeListeners() {
        if (this.galleriesEl) {
            this.onGalleryEl = this.onGalleryElClick.bind(this);
            this.galleriesEl.forEach(item => {
                item.removeEventListener('click', this.onGalleryEl);
            });
        }

        if (this.galleriesSliderEl) {
            this.onGallerySliderEl = this.onGallerySliderElClick.bind(this);
            this.galleriesSliderEl.forEach(item => {
                item.removeEventListener('click', this.onGallerySliderEl);
            });
        }
    }

    onGalleryElClick(event) {
        event.preventDefault();

        const { currentTarget } = event;
        const parentEl = currentTarget.parentElement;

        const index = Array.prototype.indexOf.call(
            parentEl.children,
            currentTarget
        );
        let sources = [];

        this.galleriesEl.forEach(item => {
            item.style.pointerEvents = 'none';
            const url = item.getAttribute('data-id');
            sources.push(url);
        });

        console.log(index);

        this.openPopup(sources, index);
    }

    onGallerySliderElClick(event) {
        event.preventDefault();
        const { currentTarget } = event;
        const parentEl = currentTarget.parentElement;

        const index = Array.prototype.indexOf.call(
            parentEl.children,
            currentTarget
        );
        let sliderSources = [];

        this.galleriesSliderEl.forEach(item => {
            item.style.pointerEvents = 'none';
            const url = item.getAttribute('data-id');
            sliderSources.push(url);
        });

        this.openPopup(sliderSources, index);
    }

    async openPopup(slides, index) {
        const formData = new FormData();
        if (slides) formData.append('gallery', JSON.stringify(slides));

        const request = await fetch(
            `${home_url}/wp-json/gallery-popup/v1/get`,
            {
                method: 'POST',
                body: formData,
            }
        );

        const res = await request.json();
        const { html } = res;

        document.body.insertAdjacentHTML('beforeEnd', html);

        const popupSwiperEl = document.querySelector('[data-popup-swiper]');
        const popupSwiperPrevEl = document.querySelector(
            '[data-popup-swiper-prev]'
        );
        const popupSwiperNextEl = document.querySelector(
            '[data-popup-swiper-next]'
        );
        const popupSwiperCurrEl = document.querySelector(
            '[data-popup-swiper-curr]'
        );

        const popupEl = document.querySelector('[data-popup]');
        new Popup(popupEl);

        const countEl = popupEl.querySelector('[data-popup-swiper-curr]');
        if (countEl) countEl.innerText = `${index + 1}`;

        this.setPopupSlider(
            popupSwiperEl,
            popupSwiperPrevEl,
            popupSwiperNextEl,
            popupSwiperCurrEl,
            index
        );
    }

    setPopupSlider(el, prevEl, nextEl, currEl, index) {
        if (this.popupSlider) this.popupSlider.destroy();

        this.popupSlider = new Swiper(el, {
            modules: [Navigation],
            initialSlide: index,
            slidesPerView: 1,
            spaceBetween: 32,
            navigation: {
                prevEl,
                nextEl,
            },
            breakpoints: {
                1680: {
                    spaceBetween: 40,
                },
            },
        });

        this.popupSlider.on('slideChange', swiper => {
            const { realIndex } = swiper;
            if (currEl) currEl.innerHTML = realIndex + 1;
        });
    }
}
